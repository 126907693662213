@keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-150px*17));
    }
  }

  .logos {
    height: 250px;
    margin: auto;
    position: relative;
    width: 90%;
    display:  grid;
    place-items: center;
    overflow: hidden;
  }

  .logos-slide {
    display: flex;
    width: calc(150px*34);
    animation: 40s slide infinite linear;
  }

  .slide {
    height: 130px;
    width: 150px;
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;
  }
  img {
    width: 100%;
  }