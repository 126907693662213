.mySwiper {
  overflow-x: hidden;
}

.swiper-slide {
  text-align: center;

  /* Center slide text vertically */
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
}

.swiper-client-msg {
  padding: 3rem 5.2rem;
  background-color: var(--third);
  border-radius: 10px;
  text-align: left;
  box-shadow: var(--shadpw);
  position: relative;
  color: #4d4d4d;
  text-align: center;
}

.swiper-client-msg::before {
  content: "";
  position: absolute;
  bottom: -10rem;
  left: 50%;
  transform: translateX(-50%);
  border: 2rem solid var(--third);
  border-color: transparent;
  border-top-color: var(--third);
}

.swiper-client-msg p::before {
  content: "\f10d";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 2rem;
  font-size: 3rem;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  color: #e3ecfe;
  z-index: 0;
}

.swiper-client-msg p::after {
  /* display: inline-block */
  content: "\f10e";
  position: absolute;
  bottom: 0%;
  right: 5%;

  font-size: 3rem;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  color: #e3ecfe;
  z-index: 0;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-client-data {
  align-items: center;
  justify-items: start;
  justify-content: start;
  gap: 1.4rem;
  margin-top: 1rem;
}

.swiper-client-data img {
  max-width: 10rem;
  height: 10rem;
  border-radius: 100%;
  border: 0.5rem solid #e3ecfe;
}

.swiper-client-data p:first-child {
  font-weight: bold;
  color: #0996f6;
}

/* testimonial section  */

@media (max-width: 780px) {
  .section-testimonial .grid-three-col {
    grid-template-columns: 2fr;
  }

  .swiper-client-msg {
    padding: 2.5rem 3rem;
  }

  .swiper-client-msg p::before {
    font-size: 2rem;
  }

  .swiper-client-msg p::after {
    font-size: 2rem;
  }
}

@media (max-width: 576px) {
  .swiper {
    margin-top: 0.5rem;
  }
}
.swiper-wrapper {
  height: 450px;
}
