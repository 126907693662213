@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
  margin: 0;
  font-family: "Nunito", sans-serif, "Helvetica Neue", system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Nunito", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
